<template>
  <div style="min-height: 80vh">
    <v-container class="text-center">
      <v-row justify="center" style="min-height: 80vh" align="center">
        <v-col class="col-6" v-if="!error">
          <v-progress-linear
            color="#e62076"
            class="mb-2"
            indeterminate
            rounded
            height="5"
          ></v-progress-linear>
          <div style="font-size: 18px">Redirecting...</div>
        </v-col>
        <v-col class="col-6" v-if="error">
          <div style="font-size: 22px; color: red">Error</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProfileService from "@/requests/Profile/ProfileService.js";
import { mapGetters, mapActions } from "vuex";
import CitypassService from "@/requests/Citypass/CitypassService";
import mobile from "@/mixins/mobileMixin";
import CartService from "@/requests/Cart/CartService";
import cityMixin from "@/mixins/cityMixin";

export default {
  mixins: [mobile, cityMixin],
  data: () => ({
    error: false,
  }),
  mounted() {
    this.getUser();
  },
  methods: {
    ...mapActions(["updateInfoLogged"]),
    ...mapActions(["updateInfo"]),
    async getUser() {
      let locale = "en";

      if (this.$route.params.locale == "es") {
        locale = "es";
      }
      this.setMobile(true);
      if (this.$route.params.menu == "true") {
        this.setMobileMenu(true);
      } else {
        this.setMobileMenu(false);
      }
      await this.updateInfo({
        locale: locale,
      });

      if (this.$route.params.token) {
        await ProfileService.getProfile(this.$route.params.token)
          .then(async (response) => {
            if (response.data.status) {
              this.updateInfoLogged({
                token: this.$route.params.token,
                locale: locale,
                id: response.data.id,
                user_type: response.data.user_type,
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                profile_image: response.data.profile_image,
              });
              //if (window.location.href.indexOf("dev") == -1) {
              await CitypassService.checkAdmin()
                .then(() => {
                  this.updateInfoLogged({
                    admin: true,
                  });
                })
                .catch(() => {
                  this.updateInfoLogged({
                    admin: false,
                  });
                });
              // } else {
              //   this.updateInfoLogged({
              //     admin: true,
              //   });
              // }
              // if (window.location.href.indexOf("localhost") > -1) {
              //   this.updateInfoLogged({
              //     admin: true,
              //   });
              // }

              if (this.mazatlan) {
                await CartService.getListOrderForUser(0).then((res) => {
                  this.citypassList = res.result;
                  this.citypassList = this.citypassList.filter(
                    (citypass) => citypass.items.length > 0
                  );

                  this.$router.push(
                    this.citypassList.length ? "/profile" : "/"
                  );
                });
              } else {
                this.$router.push("/");
              }
            } else {
              this.$router.push("/");
            }
          })
          .catch(() => {
            this.error = true;
          });
      } else {
        if (this.loggedUser) {
          await this.updateInfo({
            locale: this.loggedUser.locale,
          });
          this.$store.commit("clearUserLogged");
          await CartService.clearCart();
        }
        this.$router.push("/");
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
};
</script>