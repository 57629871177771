<template>
    <login-app />
</template>

<script>
import LoginApp from '@/components/LoginApp/LoginApp.vue'
export default {
    components: {
        LoginApp
    }
}
</script>